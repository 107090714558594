import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';

class ExpertDomains extends Component {
    state = {
        expertid: 0,
        domains: [],
        expertdomains: [],
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success'
    }

    setHandleChange = (e) => {
    }

    async componentDidMount() {
        this.setState({ loading: true });
        if (this.props.match.params.id) {
            this.setState({ expertid: this.props.match.params.id });
            let domains = await Feathers.service("domains").find({ query: { language: 1 } });
            this.setState({ domains: domains });
            let expertdomains = await Feathers.service("expertdomains").find({ query: { expert: this.props.match.params.id } });
            this.setState({ expertdomains: expertdomains, loading: false });
        }
        else {
            this.props.history.replace('/');
        }
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center"> مجال الخبرات </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/experts`} className="btn btn-primary m-2"><FaAngleRight /> السابق </Link>
                                    <Link to={`/expertbooks/${this.state.expertid}`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {this.state.domains && this.state.domains.length > 0 ? this.state.domains.map(domain => (
                                    <div className="col-xs-4 col-sm-4 text-right" key={domain.id}>
                                        <label><input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} /> {domain.name} </label>
                                    </div>
                                )) : <div>لا يوجد نتائج </div>}
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveDomains}> <FaPlus /> حــفـــظ </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertDomains;