import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class ExpertConferences extends Component {
    
    state = {
        expertid: 0,
        participations:[],
        expertconferences: [],
        expertconference: { language: 1, title: '', conferencesyear: '', conferenceplace: '', participation: ''},
        expertconferencevalidate: { title: '', conferencesyear: '', conferenceplace: '', participation: '' },
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success',
        showexpertconferencesFormok: false
    }

    setHandleChange = (e) => {
        if (e.target.type === 'text') {
            if (e.target.value.length <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertconference: { ...this.state.expertconference, [e.target.id]: e.target.value } })
                this.setState({ expertconferencevalidate: { ...this.state.expertconferencevalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'number') {
            if (e.target.value <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertconference: { ...this.state.expertconference, [e.target.id]: e.target.value } })
                this.setState({ expertconferencevalidate: { ...this.state.expertconferencevalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'select-one') {
            if (Number(e.target.value <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertconference: { ...this.state.expertconference, [e.target.id]: Number(e.target.value) } })
                this.setState({ expertconferencevalidate: { ...this.state.expertconferencevalidate, [e.target.id]: e.target.value } })
            }
        }
    }

    async getExpertConferences() {
        if (this.props.match.params.id) {
            this.setState({ loading: true, expertid: this.props.match.params.id }, async function () {
                let user = this.props.user;
                let isAuthenticated = this.props.isAuthenticated;
                if (isAuthenticated && user && user.id) {
                    if (user.id) {
                        let expertconferences = await Feathers.service("expertconferences").find({ query: { expert: this.state.expertid, include: [{ 'tbl': 'participations', 'key': 'participation', 'recordfield': 'participationname', 'tblfield': 'name' }]}});
                        this.setState({ expertconferences: expertconferences, loading: false });        
                    }
                }
            });
        }
    }

    async componentDidMount() {
        
        let participations = await Feathers.service("participations").find({ query: { language: 1 } });
        this.setState({ participations: participations });

        this.getExpertConferences()
    }

    async editItem(bookid) {
        this.setState({ loading: true });
        this.setState({ showexpertconferencesFormok: true });
        let expertconferencerec = await Feathers.service("expertconferences").get(bookid);
        if (expertconferencerec) {
            this.setState({ expertconference: expertconferencerec });
            let validatevild = Object.keys(this.state.expertconferencevalidate);
            validatevild.forEach(element => {
                this.setState({ expertconferencevalidate: { ...this.state.expertconferencevalidate, [element]: this.state.expertconference[element]}});
            });
        }
        this.setState({ loading: false });
    }

    saveExpertConference = async () => {

        let validatevild = Object.values(this.state.expertconferencevalidate);
        let validate = true;
        this.setState({ message: '' })

        validatevild.forEach(element => {
            if (element === "") {
                validate = false;
                this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
            }
        });

        if (validate) {
            if (this.state.expertconference.id) {
                let expertconferenceid = this.state.expertconference.id
                this.state.expertconference.language = 1
                this.state.expertconference.expert = Number(this.state.expertid)
                let expertconferencerec = this.state.expertconference
                delete expertconferencerec.id;
                delete expertconferencerec.modified_by
                delete expertconferencerec.modified_on
                delete expertconferencerec.created_by
                delete expertconferencerec.created_on
                let expertconference = await Feathers.service("expertconferences").patch(Number(expertconferenceid), expertconferencerec);
                if (expertconference) {
                    this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertconference: {} });
                    this.setState({ showexpertconferencesFormok: false });
                }
                this.getExpertConferences()
            }
            else {
                this.state.expertconference.language = 1
                this.state.expertconference.expert = Number(this.state.expertid)
                let expertconferencerec = this.state.expertconference
                delete expertconferencerec.id;
                delete expertconferencerec.modified_by
                delete expertconferencerec.modified_on
                delete expertconferencerec.created_by
                delete expertconferencerec.created_on
                let expertconference = await Feathers.service("expertconferences").create(expertconferencerec);
                if (expertconference) {
                    this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertconference: {}});
                    this.setState({ showexpertconferencesFormok: false });
                }
                this.getExpertConferences()
            }
        }
    }

    async deleteItem(bookid) {
        let expertconferencerec = this.state.expertconferences.find(expertconferences => expertconferences.id === bookid);
        if (expertconferencerec) {
            let expertconferences = await Feathers.service("expertconferences").remove(expertconferencerec.id, {});
            if (expertconferences) {
                this.setState({ message: "تم حذف البيانات بنجاح ..... ", messagetype: "success" });
                this.setState({ expertconference: {} });
                this.getExpertConferences();
            }
            else {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى", messagetype: "error" });
            }
        }
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-2 d-flex align-items-end">
                                    <button onClick={(e) => this.setState({ showexpertconferencesFormok: !this.state.showexpertconferencesFormok })} className="btn btn-success"><FaPlus /> </button>
                                </div>
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center"> المؤتمرات </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/expertresearchs/${this.state.expertid}`} className="btn btn-primary m-2"> <FaAngleRight /> السابق</Link>
                                    <Link to={`/expertsupervisions/${this.state.expertid}`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        {this.state.showexpertconferencesFormok ? (
                            <div className="card-body">
                                <div className="col-sm-12 text-center">
                                    <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العنوان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.title) })} id="title" autoComplete="off" defaultValue={this.state.expertconference.title} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.title)} className='feedback'>{this.state.formErrors.title ? this.state.formErrors.title : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">سنة النشر</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.conferencesyear) })} id="conferencesyear" autoComplete="off" defaultValue={this.state.expertconference.conferencesyear} onChange={this.setHandleChange} type="number" />
                                                <span hidden={!(this.state.formErrors.conferencesyear)} className='feedback'>{this.state.formErrors.conferencesyear ? this.state.formErrors.conferencesyear : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المكان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.conferenceplace) })} id="conferenceplace" autoComplete="off" defaultValue={this.state.expertconference.conferenceplace} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.conferenceplace)} className='feedback'>{this.state.formErrors.conferenceplace ? this.state.formErrors.conferenceplace : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">نوع المشاركة</label>
                                            <div className="col-sm-8">
                                                <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.participation) })} id="participation" defaultValue={this.state.expertconference.participation} onChange={this.setHandleChange}>
                                                <option value="0">أختر مجال البحث</option>
                                                {this.state.participations && this.state.participations.length > 0 ? this.state.participations.map(participation => (
                                                    <option key={participation.id} value={participation.id}>{participation.name}</option>
                                                )) : <option value="1">لا يوجد أي خيارات</option>}
                                                </select>
                                                <span hidden={!(this.state.formErrors.participation)} className='feedback'>{this.state.formErrors.participation ? this.state.formErrors.participation : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12 text-center">
                                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpertConference}><FaPlus /> حــفـــظ </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-footer">
                            <div className="row">
                                {this.state.expertconferences && this.state.expertconferences.length > 0 ? this.state.expertconferences.map(expertconference => (
                                    <div className="card border-secondary m-3 mx-auto" style={{ width: "18rem" }} key={expertconference.id}>
                                        <div className="card-body text-secondary">
                                            <p className="card-text text-justify">العنوان: {expertconference.title}</p>
                                            <p className="card-text text-justify">السنة: {expertconference.conferencesyear}</p>
                                            <p className="card-text text-justify">المكان : {expertconference.conferenceplace}</p>
                                            <p className="card-text text-justify">نوع المشاركة: {expertconference.participationname}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row col-sm-12 text-center">
                                                <button className="btn btn-secondary btn-md m-1" onClick={(e) => this.editItem(expertconference.id, e)}> <FaRegEdit /> </button>
                                                <button className="btn btn-danger btn-md m-1" onClick={(e) => this.deleteItem(expertconference.id, e)}> <FaRegTrashAlt /> </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div> لا يوجد نتائج </div>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertConferences;