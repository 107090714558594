import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class ExpertProjects extends Component {
    
    state = {
        expertid: 0,
        expertprojects: [],
        expertproject: { language: 1, title: '', place: '', from_date: '', to_date: '', document_url: '' },
        expertprojectvalidate: { title: '', place: '', from_date: '', to_date: '' },
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success',
        showexpertprojectsFormok: false
    }

    setHandleChange = (e) => {
        if (e.target.type === 'text') {
            if (e.target.value.length <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertproject: { ...this.state.expertproject, [e.target.id]: e.target.value } })
                this.setState({ expertprojectvalidate: { ...this.state.expertprojectvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'select-one') {
            if (Number(e.target.value <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertproject: { ...this.state.expertproject, [e.target.id]: Number(e.target.value) } })
                this.setState({ expertprojectvalidate: { ...this.state.expertprojectvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'date') {
            if (Number(e.target.value.length <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertproject: { ...this.state.expertproject, [e.target.id]: e.target.value } })
                this.setState({ expertprojectvalidate: { ...this.state.expertprojectvalidate, [e.target.id]: e.target.value } })
            }
        }
    }

    async getExpertProjects() {
        if (this.props.match.params.id) {
            this.setState({ loading: true, expertid: this.props.match.params.id }, async function () {
                let user = this.props.user;
                let isAuthenticated = this.props.isAuthenticated;
                if (isAuthenticated && user && user.id) {
                    if (user.id) {
                        let expertprojects = await Feathers.service("expertprojects").find({ query: { expert: this.state.expertid } });
                        this.setState({ expertprojects: expertprojects, loading: false });
                    }
                }
            });
        }
    }

    async componentDidMount() {
        this.getExpertProjects()
    }

    async editItem(bookid) {
        this.setState({ loading: true });
        this.setState({ showexpertprojectsFormok: true });
        let expertprojectrec = await Feathers.service("expertprojects").get(bookid);
        if (expertprojectrec) {
            this.setState({ expertproject: expertprojectrec });
            let validatevild = Object.keys(this.state.expertprojectvalidate);
            validatevild.forEach(element => {
                this.setState({ expertprojectvalidate: { ...this.state.expertprojectvalidate, [element]: this.state.expertproject[element]}});
            });

        }
        this.setState({ loading: false });
    }

    saveExpertProject = async () => {

        let validatevild = Object.values(this.state.expertprojectvalidate);
        let validate = true;
        this.setState({ message: '' })

        validatevild.forEach(element => {
            if (element === "") {
                validate = false;
                this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
            }
        });

        if (validate) {
            if (this.state.expertproject.id) {
                let expertprojectid = this.state.expertproject.id
                this.state.expertproject.language = 1
                this.state.expertproject.expert = Number(this.state.expertid)
                let expertprojectrec = this.state.expertproject
                delete expertprojectrec.id;
                delete expertprojectrec.modified_by
                delete expertprojectrec.modified_on
                delete expertprojectrec.created_by
                delete expertprojectrec.created_on
                let expertproject = await Feathers.service("expertprojects").patch(Number(expertprojectid), expertprojectrec);
                if (expertproject) {
                    this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertproject: {} });
                    this.setState({ showexpertprojectsFormok: false });
                }
                this.getExpertProjects()
            }
            else {
                this.state.expertproject.language = 1
                this.state.expertproject.expert = Number(this.state.expertid)
                let expertprojectrec = this.state.expertproject
                delete expertprojectrec.id;
                delete expertprojectrec.modified_by
                delete expertprojectrec.modified_on
                delete expertprojectrec.created_by
                delete expertprojectrec.created_on
                let expertproject = await Feathers.service("expertprojects").create(expertprojectrec);
                if (expertproject) {
                    this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertproject: {}});
                    this.setState({ showexpertprojectsFormok: false });
                }
                this.getExpertProjects()
            }
        }
    }

    async deleteItem(bookid) {
        let expertprojectrec = this.state.expertprojects.find(expertprojects => expertprojects.id === bookid);
        if (expertprojectrec) {
            let expertprojects = await Feathers.service("expertprojects").remove(expertprojectrec.id, {});
            if (expertprojects) {
                this.setState({ message: "تم حذف البيانات بنجاح ..... ", messagetype: "success" });
                this.setState({ expertproject: {} });
                this.getExpertProjects();
            }
            else {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى", messagetype: "error" });
            }
        }
    }

    async uploaddocument_url(event){
        let url = "https://apis.abegs.org/upload";
        event.preventDefault();
        const files = event.target.files || event.srcElement.files;
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        let res = await fetch(url, { method: 'POST', body: formData });
        let json = await res.json();
        this.setState({expertproject:{...this.state.expertproject, document_url : json.file}});
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-2 d-flex align-items-end">
                                    <button onClick={(e) => this.setState({ showexpertprojectsFormok: !this.state.showexpertprojectsFormok })} className="btn btn-success"><FaPlus /> </button>
                                </div>
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center"> المشاريع </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/expertsupervisions/${this.state.expertid}`} className="btn btn-primary m-2"> <FaAngleRight /> السابق</Link>
                                    <Link to={`/expertconsultancies/${this.state.expertid}`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        {this.state.showexpertprojectsFormok ? (
                            <div className="card-body">
                                <div className="col-sm-12 text-center">
                                    <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العنوان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.title) })} id="title" autoComplete="off" defaultValue={this.state.expertproject.title} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.title)} className='feedback'>{this.state.formErrors.title ? this.state.formErrors.title : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المكان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.place) })} id="place" autoComplete="off" defaultValue={this.state.expertproject.place} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.place)} className='feedback'>{this.state.formErrors.place ? this.state.formErrors.place : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">من تاريخ</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.from_date) })} id="from_date" autoComplete="off" defaultValue={this.state.expertproject.from_date} onChange={this.setHandleChange} type="date" />
                                                <span hidden={!(this.state.formErrors.from_date)} className='feedback'>{this.state.formErrors.from_date ? this.state.formErrors.from_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">إلى تاريخ</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.to_date) })} id="to_date" autoComplete="off" defaultValue={this.state.expertproject.to_date} onChange={this.setHandleChange} type="date"/>
                                                <span hidden={!(this.state.formErrors.to_date)} className='feedback'>{this.state.formErrors.to_date ? this.state.formErrors.to_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12 text-center">
                                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpertProject}><FaPlus /> حــفـــظ </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-footer">
                            <div className="row">
                                {this.state.expertprojects && this.state.expertprojects.length > 0 ? this.state.expertprojects.map(expertproject => (
                                    <div className="card border-secondary m-3 mx-auto" style={{ width: "18rem" }} key={expertproject.id}>
                                        <div className="card-body text-secondary">
                                            <p className="card-text text-justify">العنوان: {expertproject.title}</p>
                                            <p className="card-text text-justify">المكان: {expertproject.place}</p>
                                            <p className="card-text text-justify">من تاريخ : {expertproject.from_date}</p>
                                            <p className="card-text text-justify">إلى تاريخ: {expertproject.to_date}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row col-sm-12 text-center">
                                                <button className="btn btn-secondary btn-md m-1" onClick={(e) => this.editItem(expertproject.id, e)}> <FaRegEdit /> </button>
                                                <button className="btn btn-danger btn-md m-1" onClick={(e) => this.deleteItem(expertproject.id, e)}> <FaRegTrashAlt /> </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div> لا يوجد نتائج </div>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertProjects;