import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class ExpertResearchs extends Component {

    state = {
        expertid: 0,
        expertresearchs: [],
        expertresearch: { language: 1, title: '', authors: '', release_date: '', journal: '', volumes: '', numbers:'', researchpages:'', document_url: '' },
        expertresearchvalidate: { title: '', authors: '', release_date: '', journal: '', volumes: '', numbers:'', researchpages:''},
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success',
        showexpertresearchsFormok: false
    }

    setHandleChange = (e) => {
        if (e.target.type === 'text') {
            if (e.target.value.length <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertresearch: { ...this.state.expertresearch, [e.target.id]: e.target.value } })
                this.setState({ expertresearchvalidate: { ...this.state.expertresearchvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'number') {
            if (e.target.value <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertresearch: { ...this.state.expertresearch, [e.target.id]: e.target.value } })
                this.setState({ expertresearchvalidate: { ...this.state.expertresearchvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'select-one') {
            if (Number(e.target.value <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertresearch: { ...this.state.expertresearch, [e.target.id]: Number(e.target.value) } })
                this.setState({ expertresearchvalidate: { ...this.state.expertresearchvalidate, [e.target.id]: e.target.value } })
            }
        }
    }


    async getExpertresearchs() {
        if (this.props.match.params.id) {
            this.setState({ loading: true, expertid: this.props.match.params.id }, async function () {
                let user = this.props.user;
                let isAuthenticated = this.props.isAuthenticated;
                if (isAuthenticated && user && user.id) {
                    if (user.id) {
                        let expertresearchs = await Feathers.service("expertresearchs").find({ query: { expert: this.state.expertid } });
                        this.setState({ expertresearchs: expertresearchs, loading: false });
                    }
                }
            });
        }
    }


    async componentDidMount() {
        this.getExpertresearchs()
    }

    async editItem(bookid) {
        this.setState({ loading: true });
        this.setState({ showexpertresearchsFormok: true });
        let expertresearchrec = await Feathers.service("expertresearchs").get(bookid);
        if (expertresearchrec) {
            this.setState({ expertresearch: expertresearchrec });
            let validatevild = Object.entries(this.state.expertresearchvalidate);
            validatevild.forEach(element => {
                this.setState({ expertresearchvalidate: { ...this.state.expertresearchvalidate, [element]: this.state.expertresearch[element]}});
            });
        }
        this.setState({ loading: false });
    }

    saveExpertResearch = async () => {

        let validatevild = Object.values(this.state.expertresearchvalidate);
        let validate = true;
        this.setState({ message: '' })

        validatevild.forEach(element => {
            if (element === "") {
                validate = false;
                this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
            }
        });

        if (validate) {
            if (this.state.expertresearch.id) {
                let expertresearchid = this.state.expertresearch.id
                this.state.expertresearch.language = 1
                this.state.expertresearch.expert = Number(this.state.expertid)
                let expertresearchrec = this.state.expertresearch
                delete expertresearchrec.id;
                delete expertresearchrec.modified_by
                delete expertresearchrec.modified_on
                delete expertresearchrec.created_by
                delete expertresearchrec.created_on
                let expertresearch = await Feathers.service("expertresearchs").patch(Number(expertresearchid), expertresearchrec);
                if (expertresearch) {
                    this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertresearch: {} });
                    this.setState({ showexpertresearchsFormok: false });
                }
                this.getExpertresearchs()
            }
            else {
                this.state.expertresearch.language = 1
                this.state.expertresearch.expert = Number(this.state.expertid)
                let expertresearchrec = this.state.expertresearch
                delete expertresearchrec.id;
                delete expertresearchrec.modified_by
                delete expertresearchrec.modified_on
                delete expertresearchrec.created_by
                delete expertresearchrec.created_on
                let expertresearch = await Feathers.service("expertresearchs").create(expertresearchrec);
                if (expertresearch) {
                    this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertresearch: {} });
                    this.setState({ showexpertresearchsFormok: false });
                }
                this.getExpertresearchs()
            }

        }
    }

    async deleteItem(bookid) {
        let expertresearchrec = this.state.expertresearchs.find(expertresearchs => expertresearchs.id === bookid);
        if (expertresearchrec) {
            let expertresearchs = await Feathers.service("expertresearchs").remove(expertresearchrec.id, {});
            if (expertresearchs) {
                this.setState({ message: "تم حذف البيانات بنجاح ..... ", messagetype: "success" });
                this.setState({ expertresearch: {} });
                this.getExpertresearchs();
            }
            else {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى", messagetype: "error" });
            }
        }
    }

    async uploaddocument_url(event){
        let url = "https://apis.abegs.org/upload";
        event.preventDefault();
        const files = event.target.files || event.srcElement.files;
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        let res = await fetch(url, { method: 'POST', body: formData });
        let json = await res.json();
        this.setState({expertresearch:{...this.state.expertresearch, document_url : json.file}});
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-2 d-flex align-items-end">
                                    <button onClick={(e) => this.setState({ showexpertresearchsFormok: !this.state.showexpertresearchsFormok })} className="btn btn-success"><FaPlus /> </button>
                                </div>
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center">  البحوث والدراسات  </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/expertbooks/${this.state.expertid}`} className="btn btn-primary m-2"> <FaAngleRight /> السابق</Link>
                                    <Link to={`/expertconferences/${this.state.expertid}`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        {this.state.showexpertresearchsFormok ? (
                            <div className="card-body">
                                <div className="col-sm-12 text-center">
                                    <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العنوان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.title) })} id="title" autoComplete="off" defaultValue={this.state.expertresearch.title} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.title)} className='feedback'>{this.state.formErrors.title ? this.state.formErrors.title : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المؤلف</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.authors) })} id="authors" autoComplete="off" defaultValue={this.state.expertresearch.authors} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.authors)} className='feedback'>{this.state.formErrors.authors ? this.state.formErrors.authors : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المجلة</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.journal) })} id="journal" autoComplete="off" defaultValue={this.state.expertresearch.journal} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.journal)} className='feedback'>{this.state.formErrors.journal ? this.state.formErrors.journal : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">سنة النشر</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.release_date) })} id="release_date" autoComplete="off" defaultValue={this.state.expertresearch.release_date} onChange={this.setHandleChange} type="number" />
                                                <span hidden={!(this.state.formErrors.release_date)} className='feedback'>{this.state.formErrors.release_date ? this.state.formErrors.release_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العدد</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.volumes) })} id="volumes" autoComplete="off" defaultValue={this.state.expertresearch.volumes} onChange={this.setHandleChange}  type="number" />
                                                <span hidden={!(this.state.formErrors.volumes)} className='feedback'>{this.state.formErrors.volumes ? this.state.formErrors.volumes : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المجلد</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.numbers) })} id="numbers" autoComplete="off" defaultValue={this.state.expertresearch.numbers} onChange={this.setHandleChange}  type="number" />
                                                <span hidden={!(this.state.formErrors.numbers)} className='feedback'>{this.state.formErrors.numbers ? this.state.formErrors.numbers : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">الصفحات</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.researchpages) })} id="researchpages" autoComplete="off" defaultValue={this.state.expertresearch.researchpages} onChange={this.setHandleChange}  type="number" />
                                                <span hidden={!(this.state.formErrors.researchpages)} className='feedback'>{this.state.formErrors.researchpages ? this.state.formErrors.researchpages : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12 text-center">
                                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpertResearch}><FaPlus /> حــفـــظ </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-footer">
                            <div className="row">
                                {this.state.expertresearchs && this.state.expertresearchs.length > 0 ? this.state.expertresearchs.map(expertresearch => (
                                    <div className="card border-secondary m-3 mx-auto" style={{ width: "18rem" }} key={expertresearch.id}>
                                        <div className="card-body text-secondary">
                                            <p className="card-text text-justify">العنوان: {expertresearch.title}</p>
                                            <p className="card-text text-justify">المؤلف: {expertresearch.authors}</p>
                                            <p className="card-text text-justify">المجلة: {expertresearch.journal}</p>
                                            <p className="card-text text-justify">السنة : {expertresearch.release_date}</p>
                                            <p className="card-text text-justify">العدد: {expertresearch.volumes}</p>
                                            <p className="card-text text-justify">المجلد: {expertresearch.numbers}</p>
                                            <p className="card-text text-justify">الصفحات: {expertresearch.researchpages}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row col-sm-12 text-center">
                                                <button className="btn btn-secondary btn-md m-1" onClick={(e) => this.editItem(expertresearch.id, e)}> <FaRegEdit /> </button>
                                                <button className="btn btn-danger btn-md m-1" onClick={(e) => this.deleteItem(expertresearch.id, e)}> <FaRegTrashAlt /> </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div> لا يوجد نتائج </div>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertResearchs;