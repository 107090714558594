import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppNavbar from './components/layout/AppNavbar';
import { createBrowserHistory } from "history";
import Feathers from './Feathers';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Experts from './components/Experts';
import ExpertDomains from './components/ExpertDomains';
import ExpertBooks from './components/ExpertBooks';
import ExpertResearchs from './components/ExpertResearchs';
import ExpertConferences from './components/ExpertConferences';
import ExpertSupervisions from './components/ExpertSupervisions';
import ExpertProjects from './components/ExpertProjects';
import ExpertConsultancies from './components/ExpertConsultancies';
import ExpertCourses from './components/ExpertCourses';
const history = createBrowserHistory();

class App extends Component {

  state = { user:null, isAuthenticated:false};

  parentSetUserData=(user, expert)=>{
    let isAuthenticated = user.id ? true :false;
    let expertid =  expert ? expert :null;
    this.setState({user:user, expertid:expertid, isAuthenticated});
  }

  parentClearUserData=()=>{
    this.setState({user:null, isAuthenticated:false});
    Feathers.logout();
  }

  render() {
    return (
      <Router>
        <div className="App">
          <AppNavbar history={history} user={this.state.user} isAuthenticated={this.state.isAuthenticated} expertid={this.state.expertid} parentClearUserData={this.parentClearUserData}/>
          <div className="container">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Experts {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              <Route
                exact
                path="/expertdomains/:id"
                render={(props) => <ExpertDomains {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertbooks/:id"
                render={(props) => <ExpertBooks {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertresearchs/:id"
                render={(props) => <ExpertResearchs {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertconferences/:id"
                render={(props) => <ExpertConferences {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertsupervisions/:id"
                render={(props) => <ExpertSupervisions {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertprojects/:id"
                render={(props) => <ExpertProjects {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertconsultancies/:id"
                render={(props) => <ExpertConsultancies {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                exact
                path="/expertcourses/:id"
                render={(props) => <ExpertCourses {...props} user={this.state.user} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              />
              <Route
                  exact
                  path="/login"
                  render={(props) => <Login {...props} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
              <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} isAuthenticated={this.state.isAuthenticated} parentSetUserData={this.parentSetUserData} />}/>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
