import React, { Component } from 'react'
import Feathers from '../Feathers';
import Alert from './layout/Alert';
import { FaUserAlt, FaUserPlus } from 'react-icons/fa';

class Login extends Component {

  state = { message: null, messageType: null, email: '', password: '' };

  setHandleChang = (e) => {
    this.setState({ [e.target.id]: e.target.value })

  }

  login = async e => {
    e.preventDefault();
    let expertid = null;
    let { email, password } = this.state;
    let myReg = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    if (email.trim() !== '' && password.trim() !== '' && password.trim().length > 5 && myReg.test(email)) {
      try {
          await Feathers.loginUser({ email: this.state.email, password: this.state.password, strategy: "local" }).then(async user => {
              if (Feathers.client.get('user') && Feathers.client.get('user').id){
                let expert = await Feathers.service("experts").find({ query: { created_by: Number(Feathers.client.get('user').id)}});
                if (expert.length>0) {
                  expertid = expert[0].id
                }
                else {
                  expertid = null;
                }
                this.props.parentSetUserData(Feathers.client.get('user'), expertid);
                this.props.history.replace('/');
              }
        }).catch(err => {
          this.setState({ message: "هناك خطأ في اسم المستخدم أو كلمة المرور ..." + err.message });
        });
      }
      catch (err) {
        this.setState({ message: "هناك خطأ في اسم المستخدم أو كلمة المرور ..." + err.message });
      }
    } else {
      this.setState({ message: "الرجاء التأكد من المدخلات ..... " });
    }
  }

  register = async e => {
    e.preventDefault();
    this.props.history.replace('/register');
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-body">
              {this.state.message ? (
                <Alert message={this.state.message} messageType="error" />) : null}
              <h4 className="text-center pb-4 pt-3">
                <span className="text-primary">
                  تسجيل الدخول
                </span>
              </h4>
              <form onSubmit={e => e.preventDefault() && false}>
                <div className="form-group">
                  <label htmlFor="email">البريد الإلكتروني:</label>
                  <input type="email" className="form-control" id="email" autoComplete="off" autoFocus value={this.state.email} onChange={this.setHandleChang} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">كلمة المرور:</label>
                  <input className="form-control" type="password" id="password" autoComplete="off" value={this.state.password} onChange={this.setHandleChang} />
                </div>
                <button type="submit" className="btn btn-primary" onClick={this.login}><FaUserAlt /> تسجيل الدخول</button>
                {' '}
                <button type="button" className="btn btn-success" onClick={this.register}><FaUserPlus /> مستخدم جديد</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login;
