import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class ExpertCourses extends Component {
       
    state = {
        expertid: 0,
        expertcourses: [],
        expertcourse: { language: 1, title: '', place: '', from_date: '', to_date: '', document_url: '' },
        expertcoursevalidate: { title: '', place: '', from_date: '', to_date: '' },
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success',
        showexpertcoursesFormok: false
    }

    setHandleChange = (e) => {
        if (e.target.type === 'text') {
            if (e.target.value.length <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertcourse: { ...this.state.expertcourse, [e.target.id]: e.target.value } })
                this.setState({ expertcoursevalidate: { ...this.state.expertcoursevalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'select-one') {
            if (Number(e.target.value <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertcourse: { ...this.state.expertcourse, [e.target.id]: Number(e.target.value) } })
                this.setState({ expertcoursevalidate: { ...this.state.expertcoursevalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'date') {
            if (Number(e.target.value.length <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertcourse: { ...this.state.expertcourse, [e.target.id]: e.target.value } })
                this.setState({ expertcoursevalidate: { ...this.state.expertcoursevalidate, [e.target.id]: e.target.value } })
            }
        }
    }

    async getExpertCourses() {
        if (this.props.match.params.id) {
            this.setState({ loading: true, expertid: this.props.match.params.id }, async function () {
                let user = this.props.user;
                let isAuthenticated = this.props.isAuthenticated;
                if (isAuthenticated && user && user.id) {
                    if (user.id) {
                        let expertcourses = await Feathers.service("expertcourses").find({ query: { expert: this.state.expertid } });
                        this.setState({ expertcourses: expertcourses, loading: false });
                    }
                }
            });
        }
    }

    async componentDidMount() {
        this.getExpertCourses()
    }

    async editItem(bookid) {
        this.setState({ loading: true });
        this.setState({ showexpertcoursesFormok: true });
        let expertcourserec = await Feathers.service("expertcourses").get(bookid);
        if (expertcourserec) {
            this.setState({ expertcourse: expertcourserec });
            let validatevild = Object.keys(this.state.expertcoursevalidate);
            validatevild.forEach(element => {
                this.setState({ expertcoursevalidate: { ...this.state.expertcoursevalidate, [element]: this.state.expertcourse[element]}});
            });

        }
        this.setState({ loading: false });
    }

    saveExpertCourse = async () => {

        let validatevild = Object.values(this.state.expertcoursevalidate);
        let validate = true;
        this.setState({ message: '' })

        validatevild.forEach(element => {
            if (element === "") {
                validate = false;
                this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
            }
        });

        if (validate) {
            if (this.state.expertcourse.id) {
                let expertcourseid = this.state.expertcourse.id
                this.state.expertcourse.language = 1
                this.state.expertcourse.expert = Number(this.state.expertid)
                let expertcourserec = this.state.expertcourse
                delete expertcourserec.id;
                delete expertcourserec.modified_by
                delete expertcourserec.modified_on
                delete expertcourserec.created_by
                delete expertcourserec.created_on
                let expertcourse = await Feathers.service("expertcourses").patch(Number(expertcourseid), expertcourserec);
                if (expertcourse) {
                    this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertcourse: {} });
                    this.setState({ showexpertcoursesFormok: false });
                }
                this.getExpertCourses()
            }
            else {
                this.state.expertcourse.language = 1
                this.state.expertcourse.expert = Number(this.state.expertid)
                let expertcourserec = this.state.expertcourse
                delete expertcourserec.id;
                delete expertcourserec.modified_by
                delete expertcourserec.modified_on
                delete expertcourserec.created_by
                delete expertcourserec.created_on
                let expertcourse = await Feathers.service("expertcourses").create(expertcourserec);
                if (expertcourse) {
                    this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertcourse: {}});
                    this.setState({ showexpertcoursesFormok: false });
                }
                this.getExpertCourses()
            }
        }
    }

    async deleteItem(bookid) {
        let expertcourserec = this.state.expertcourses.find(expertcourses => expertcourses.id === bookid);
        if (expertcourserec) {
            let expertcourses = await Feathers.service("expertcourses").remove(expertcourserec.id, {});
            if (expertcourses) {
                this.setState({ message: "تم حذف البيانات بنجاح ..... ", messagetype: "success" });
                this.setState({ expertcourse: {} });
                this.getExpertCourses();
            }
            else {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى", messagetype: "error" });
            }
        }
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-2 d-flex align-items-end">
                                    <button onClick={(e) => this.setState({ showexpertcoursesFormok: !this.state.showexpertcoursesFormok })} className="btn btn-success"><FaPlus /> </button>
                                </div>
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center"> الدورات التدريبية </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/expertconsultancies/${this.state.expertid}`} className="btn btn-primary m-2"> <FaAngleRight /> السابق</Link>
                                    <Link to={`/`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        {this.state.showexpertcoursesFormok ? (
                            <div className="card-body">
                                <div className="col-sm-12 text-center">
                                    <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العنوان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.title) })} id="title" autoComplete="off" defaultValue={this.state.expertcourse.title} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.title)} className='feedback'>{this.state.formErrors.title ? this.state.formErrors.title : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المكان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.place) })} id="place" autoComplete="off" defaultValue={this.state.expertcourse.place} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.place)} className='feedback'>{this.state.formErrors.place ? this.state.formErrors.place : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">من تاريخ</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.from_date) })} id="from_date" autoComplete="off" defaultValue={this.state.expertcourse.from_date} onChange={this.setHandleChange} type="date" />
                                                <span hidden={!(this.state.formErrors.from_date)} className='feedback'>{this.state.formErrors.from_date ? this.state.formErrors.from_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">إلى تاريخ</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.to_date) })} id="to_date" autoComplete="off" defaultValue={this.state.expertcourse.to_date} onChange={this.setHandleChange} type="date"/>
                                                <span hidden={!(this.state.formErrors.to_date)} className='feedback'>{this.state.formErrors.to_date ? this.state.formErrors.to_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12 text-center">
                                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpertCourse}><FaPlus /> حــفـــظ </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-footer">
                            <div className="row">
                                {this.state.expertcourses && this.state.expertcourses.length > 0 ? this.state.expertcourses.map(expertcourse => (
                                    <div className="card border-secondary m-3 mx-auto" style={{ width: "18rem" }} key={expertcourse.id}>
                                        <div className="card-body text-secondary">
                                            <p className="card-text text-justify">العنوان: {expertcourse.title}</p>
                                            <p className="card-text text-justify">المكان: {expertcourse.place}</p>
                                            <p className="card-text text-justify">من تاريخ : {expertcourse.from_date}</p>
                                            <p className="card-text text-justify">إلى تاريخ: {expertcourse.to_date}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row col-sm-12 text-center">
                                                <button className="btn btn-secondary btn-md m-1" onClick={(e) => this.editItem(expertcourse.id, e)}> <FaRegEdit /> </button>
                                                <button className="btn btn-danger btn-md m-1" onClick={(e) => this.deleteItem(expertcourse.id, e)}> <FaRegTrashAlt /> </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div> لا يوجد نتائج </div>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertCourses;