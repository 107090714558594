import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class Experts extends Component {

  state = {
    expert: { name: '', gender: '', country: '', nationality: '', degree: '', academic: '', university: '', major: '', specialization: '', specializationyears: '', job: '', dateofbirth: '', experienceyears: '', workplace: '', mobile: '', email: '', address: ''},
    formErrors: {},
    genders: [],
    countries: [],
    nationalities: [],
    degrees: [],
    academics: [],
    loading: false,
    message: null,
    messagetype: 'success'
  };



  setHandleChange = (e) => {
    if (e.target.type === 'text') {
      if (e.target.value.length <= 0) {
        this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
      }
      else {
        this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
        this.setState({ expert: { ...this.state.expert, [e.target.id]: e.target.value } })
      }
    }
    if (e.target.type === 'select-one') {
      if (Number(e.target.value <= 0)) {
        this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
      }
      else {
        this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
        this.setState({ expert: { ...this.state.expert, [e.target.id]: Number(e.target.value) } })
      }
    }
  }

  async componentDidMount() {

    let user = this.props.user;
    let isAuthenticated = this.props.isAuthenticated;

    if (isAuthenticated && user && user.id) {

      this.setState({ loading: true });

      if (user.id) {

        let genders = await Feathers.service("genders").find({ query: { language: 1 } });
        this.setState({ genders: genders });

        let countries = await Feathers.service("countries").find({ query: { language: 1 } });
        this.setState({ countries: countries });

        let nationalities = await Feathers.service("nationalities").find({ query: { language: 1 } });
        this.setState({ nationalities: nationalities });

        let degrees = await Feathers.service("degrees").find({ query: { language: 1 } });
        this.setState({ degrees: degrees });

        let academics = await Feathers.service("academics").find({ query: { language: 1 } });
        this.setState({ academics: academics });

        let expert = await Feathers.service("experts").find({ query: { created_by: Number(user.id) } });
        if (expert.length>0) {
          this.setState({ expert: expert[0], loading: false, userid: user.id});
        }
        else {
          this.setState({ expert: { name: '', gender: '', country: '', nationality: '', degree: '', academic: '', university: '', major: '', specialization: '', specializationyears: '', job: '', dateofbirth: '', experienceyears: '', workplace: '', mobile: '', email: '', address: '' }, loading: false, userid: user.id });
        }

      } else {
        this.props.history.replace("/login");
      }
    }
    else {
      this.props.history.replace("/login");
    }
  }


  saveExpert = async () => {

    let v = Object.values(this.state.formErrors);
    let validate = true;

    v.forEach(element => {
      if (element !== "") {
        validate = false;
        this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
      }
    });

    if (validate) {
      let expert = await Feathers.service("experts").find({ query: { created_by: Number(this.state.userid)}});
      if (expert.length>0) {
        let expertid = this.state.expert.id;
        let expertsname = this.state.expert.name.split(" ");
        let expertsmajor = this.state.expert.major.split(" ");
        let expertsspecialization = this.state.expert.specialization.split(" ");
        let expertstags = [...expertsname, ...expertsmajor, ...expertsmajor, ...expertsspecialization];
        this.state.expert.language = 1;
        let expertrec = { ...this.state.expert, expertstags: expertstags };
        delete expertrec.id;
        delete expertrec.modified_by
        delete expertrec.modified_on
        delete expertrec.created_by
        delete expertrec.created_on
        let experts = await Feathers.service("experts").patch(Number(expertid), expertrec);
        if (experts) {
          this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
        }
      }
      else {
        let expertsname = this.state.expert.name.split(" ");
        let expertsmajor = this.state.expert.major.split(" ");
        let expertsspecialization = this.state.expert.specialization.split(" ");
        let expertstags = [...expertsname, ...expertsmajor, ...expertsmajor, ...expertsspecialization];
        this.state.expert.language = 1;
        let expertrec = { ...this.state.expert, expertstags: expertstags };
        delete expertrec.id;
        delete expertrec.modified_by
        delete expertrec.modified_on
        delete expertrec.created_by
        delete expertrec.created_on
        let experts = await Feathers.service("experts").create(expertrec);
        if (experts) {
          this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
        }
      }
    }
  }

  render() {
    const { loading } = this.state;
    if (this.props.isAuthenticated) {
      if (!loading) {
        return (
          <div className="container">
            <p>دليل الخبراء التربويين</p>
            {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
            <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الاسم</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.name) })} id="name" autoComplete="off" defaultValue={this.state.expert.name} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.name)} className='feedback'>{this.state.formErrors.name ? this.state.formErrors.name : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الجنس</label>
                    <div className="col-sm-8">
                      <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.gender) })} id="gender" defaultValue={this.state.expert.gender} onChange={this.setHandleChange}>
                        <option value="0">أختر الجنس </option>
                        {this.state.genders && this.state.genders.length > 0 ? this.state.genders.map(gender => (
                          <option key={gender.id} value={gender.id}>{gender.name}</option>
                        )) : <option value="1">لا يوجد أي خيارات</option>}
                      </select>
                      <span hidden={!(this.state.formErrors.gender)} className='feedback'>{this.state.formErrors.gender ? this.state.formErrors.gender : ''} </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الدولة</label>
                    <div className="col-sm-8">
                      <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.country) })} id="country" defaultValue={this.state.expert.country} onChange={this.setHandleChange}>
                        <option value="0">أختر الدولة </option>
                        {this.state.countries && this.state.countries.length > 0 ? this.state.countries.map(country => (
                          <option key={country.id} value={country.id}>{country.name}</option>
                        )) : <option value="1">لا يوجد أي خيارات</option>}
                      </select>
                      <span hidden={!(this.state.formErrors.country)} className='feedback'>{this.state.formErrors.country ? this.state.formErrors.country : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الجنسية</label>
                    <div className="col-sm-8">
                      <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.nationality) })} id="nationality" defaultValue={this.state.expert.nationality} onChange={this.setHandleChange}>
                        <option value="0">أختر الجنسية </option>
                        {this.state.nationalities && this.state.nationalities.length > 0 ? this.state.nationalities.map(nationality => (
                          <option key={nationality.id} value={nationality.id}>{nationality.name}</option>
                        )) : <option value="1">لا يوجد أي خيارات</option>}
                      </select>
                      <span hidden={!(this.state.formErrors.nationality)} className='feedback'>{this.state.formErrors.nationality ? this.state.formErrors.nationality : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">المؤهل</label>
                    <div className="col-sm-8">
                      <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.degree) })} id="degree" defaultValue={this.state.expert.degree} onChange={this.setHandleChange}>
                        <option value="0">أختر المؤهل</option>
                        {this.state.degrees && this.state.degrees.length > 0 ? this.state.degrees.map(degree => (
                          <option key={degree.id} value={degree.id}>{degree.name}</option>
                        )) : <option value="1">لا يوجد أي خيارات</option>}
                      </select>
                      <span hidden={!(this.state.formErrors.degree)} className='feedback'>{this.state.formErrors.degree ? this.state.formErrors.degree : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الرتبة العلمية</label>
                    <div className="col-sm-8">
                      <select className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.academic) })} id="academic" defaultValue={this.state.expert.academic} onChange={this.setHandleChange}>
                        <option value="0">أختر الرتبة العلمية</option>
                        {this.state.academics && this.state.academics.length > 0 ? this.state.academics.map(academic => (
                          <option key={academic.id} value={academic.id}>{academic.name}</option>
                        )) : <option value="1">لا يوجد أي خيارات</option>}
                      </select>
                      <span hidden={!(this.state.formErrors.academic)} className='feedback'>{this.state.formErrors.academic ? this.state.formErrors.academic : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الجامعة</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.university) })} id="university" autoComplete="off" defaultValue={this.state.expert.university} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.university)} className='feedback'>{this.state.formErrors.university ? this.state.formErrors.university : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">التخصص العام</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.major) })} id="major" autoComplete="off" defaultValue={this.state.expert.major} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.major)} className='feedback'>{this.state.formErrors.major ? this.state.formErrors.major : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">التخصص الدقيق</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.specialization) })} id="specialization" autoComplete="off" defaultValue={this.state.expert.specialization} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.specialization)} className='feedback'>{this.state.formErrors.specialization ? this.state.formErrors.specialization : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">سنوات الخبرة</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.specializationyears) })} id="specializationyears" autoComplete="off" defaultValue={this.state.expert.specializationyears} onChange={this.setHandleChange} type="number" />
                      <span hidden={!(this.state.formErrors.specializationyears)} className='feedback'>{this.state.formErrors.specializationyears ? this.state.formErrors.specializationyears : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label"> الوظيفة الحالية </label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.job) })} id="job" autoComplete="off" defaultValue={this.state.expert.job} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.job)} className='feedback'>{this.state.formErrors.job ? this.state.formErrors.job : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">تاريخ الميلاد</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.dateofbirth) })} id="dateofbirth" autoComplete="off" defaultValue={this.state.expert.dateofbirth} onChange={this.setHandleChange} type="Date" />
                      <span hidden={!(this.state.formErrors.dateofbirth)} className='feedback'>{this.state.formErrors.dateofbirth ? this.state.formErrors.dateofbirth : ''} </span>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">سنوات الخدمة</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.experienceyears) })} id="experienceyears" autoComplete="off" defaultValue={this.state.expert.experienceyears} onChange={this.setHandleChange} type="number" />
                      <span hidden={!(this.state.formErrors.experienceyears)} className='feedback'>{this.state.formErrors.experienceyears ? this.state.formErrors.experienceyears : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">مكان العمل</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.workplace) })} id="workplace" autoComplete="off" defaultValue={this.state.expert.workplace} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.workplace)} className='feedback'>{this.state.formErrors.workplace ? this.state.formErrors.workplace : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الجوال</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.mobile) })} id="mobile" autoComplete="off" defaultValue={this.state.expert.mobile} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.mobile)} className='feedback'>{this.state.formErrors.mobile ? this.state.formErrors.mobile : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">الهاتف</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.phone) })} id="phone" autoComplete="off" defaultValue={this.state.expert.phone} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.phone)} className='feedback'>{this.state.formErrors.phone ? this.state.formErrors.phone : ''} </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="row">

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">البريد الإلكتروني</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.email) })} id="email" autoComplete="off" defaultValue={this.state.expert.email} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.email)} className='feedback'>{this.state.formErrors.email ? this.state.formErrors.email : ''} </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-4 control-label">العنوان</label>
                    <div className="col-sm-8">
                      <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.address) })} id="address" autoComplete="off" defaultValue={this.state.expert.address} onChange={this.setHandleChange} />
                      <span hidden={!(this.state.formErrors.address)} className='feedback'>{this.state.formErrors.address ? this.state.formErrors.address : ''} </span>
                    </div>
                  </div>
                </div>

              </div>

              <div className="form-group">
                <div className="col-sm-12 text-center">
                  <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpert}><FaPlus /> حــفـــظ </button>
                  <Link to={`/expertdomains/${this.state.expert.id}`} className="btn btn-primary m-2"> الــتـــالي <FaAngleLeft /></Link>
                </div>
              </div>


            </form>
          </div >
        );
      } else {
        return <Spinner />;
      }
    }
    else {
      return (
        <div className="container">
          <p>
            سعى مكتب التربية العربي لدول الخليج (ABEGS) لتحقيق التميز والتفوق في العمل التربوي المشترك، من خلال دعم التعاون والتنسيق والتكامل بين الدول الاعضاء.
  
  كما يقدم المكتب المساعدة والمشورة للدول الاعضاء، ويعمل على نقل الممارسات والتجارب التربوية المتميزة لها لتعزيز القدرات الوطنية سعياً لتلبية متطلبات اقتصاد المعرفة، ويولي المكتب اهتماماً كبيراً لتعزيز قيم المواطنة في الدول الأعضاء.
  
  ومن أجل تحقيق هذه التطلعات، يتبنى المكتب برنامجاً طموحاً لبناء قاعدة بيانات للخبراء المتخصصين في التعليم والتنمية البشرية والتطوير الإداري. هذا، وسيستفيد المكتب من قاعدة البيانات في التعاقد مع المتخصصين المؤهلين في برامجه ومشاريعه.
  
  وسوف تتاح قاعدة البيانات أيضا للوزارات والجهات الحكومية التي تطلب ذلك، وأنها ستكون بمثابة بوابة تعريفية للمتخصصين تسهم في التعريف بخبراتهم ومنتجاتهم العلمية والمعرفية.
          </p>
        </div>
      );
    }

  }
}
export default Experts;