import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaAngleLeft, FaAngleRight, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Alert from './layout/Alert';
import Spinner from './layout/Spinner';
import Feathers from '../Feathers';
import classnames from 'classnames';


class ExpertBooks extends Component {

    state = {
        expertid: 0,
        expertbooks: [],
        expertbook: { language: 1, title: '', authors: '', release_date: '', publisher: '', country: '' },
        expertbookvalidate: { title: '', authors: '', release_date: '', publisher: '', country: '' },
        formErrors: {},
        loading: false,
        message: null,
        messagetype: 'success',
        showexpertbooksFormok: false
    }

    setHandleChange = (e) => {
        if (e.target.type === 'text') {
            if (e.target.value.length <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertbook: { ...this.state.expertbook, [e.target.id]: e.target.value } })
                this.setState({ expertbookvalidate: { ...this.state.expertbookvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'number') {
            if (e.target.value <= 0) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب إدخال بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertbook: { ...this.state.expertbook, [e.target.id]: e.target.value } })
                this.setState({ expertbookvalidate: { ...this.state.expertbookvalidate, [e.target.id]: e.target.value } })
            }
        }
        if (e.target.type === 'select-one') {
            if (Number(e.target.value <= 0)) {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: 'يجب اختيار بيانات هذا الحقل' } })
            }
            else {
                this.setState({ formErrors: { ...this.state.formErrors, [e.target.id]: '' } })
                this.setState({ expertbook: { ...this.state.expertbook, [e.target.id]: Number(e.target.value) } })
                this.setState({ expertbookvalidate: { ...this.state.expertbookvalidate, [e.target.id]: e.target.value } })
            }
        }
    }

    async getExpertbooks() {
        if (this.props.match.params.id) {
            this.setState({ loading: true, expertid: this.props.match.params.id }, async function () {
                let user = this.props.user;
                let isAuthenticated = this.props.isAuthenticated;
                if (isAuthenticated && user && user.id) {
                    if (user.id) {
                        let expertbooks = await Feathers.service("expertbooks").find({ query: { expert: this.state.expertid } });
                        this.setState({ expertbooks: expertbooks, loading: false });
                    }
                }
            });
        }
    }

    async componentDidMount() {
        this.getExpertbooks();
    }

    async editItem(bookid) {
        this.setState({ loading: true });
        this.setState({ showexpertbooksFormok: true });
        let expertbookrec = await Feathers.service("expertbooks").get(bookid);
        if (expertbookrec) {
            this.setState({ expertbook: expertbookrec });
            let validatevild = Object.keys(this.state.expertbookvalidate);
            validatevild.forEach(element => {
                this.setState({ expertbookvalidate: { ...this.state.expertbookvalidate, [element]: this.state.expertbook[element]}});
            });

        }
        this.setState({ loading: false });
    }

    saveExpertBook = async () => {

        let validatevild = Object.values(this.state.expertbookvalidate);
        let validate = true;
        this.setState({ message: '' })

        validatevild.forEach(element => {
            if (element === "") {
                validate = false;
                this.setState({ message: "هناك بيانات تحتاج الى إضافة تأكد من ادخال جميع البيانات", messagetype: "error" });
            }
        });

        if (validate) {
            if (this.state.expertbook.id) {
                let expertbookid = this.state.expertbook.id
                this.state.expertbook.language = 1
                this.state.expertbook.expert = Number(this.state.expertid)
                let expertbookrec = this.state.expertbook
                delete expertbookrec.id;
                delete expertbookrec.modified_by
                delete expertbookrec.modified_on
                delete expertbookrec.created_by
                delete expertbookrec.created_on
                let expertbook = await Feathers.service("expertbooks").patch(Number(expertbookid), expertbookrec);
                if (expertbook) {
                    this.setState({ message: "تم تعديل البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertbook: {} });
                    this.setState({ showexpertbooksFormok: false });
                }
                this.getExpertbooks()
            }
            else {
                this.state.expertbook.language = 1
                this.state.expertbook.expert = Number(this.state.expertid)
                let expertbookrec = this.state.expertbook
                delete expertbookrec.id;
                delete expertbookrec.modified_by
                delete expertbookrec.modified_on
                delete expertbookrec.created_by
                delete expertbookrec.created_on
                let expertbook = await Feathers.service("expertbooks").create(expertbookrec);
                if (expertbook) {
                    this.setState({ message: "تم إضافة البيانات بنجاح ..... ", messagetype: "success" });
                    this.setState({ expertbook: {}});
                    this.setState({ showexpertbooksFormok: false });
                }
                this.getExpertbooks()
            }
        }
    }

    async deleteItem(bookid) {
        let expertbookrec = this.state.expertbooks.find(expertbooks => expertbooks.id === bookid);
        if (expertbookrec) {
            let expertbooks = await Feathers.service("expertbooks").remove(expertbookrec.id, {});
            if (expertbooks) {
                this.setState({ message: "تم حذف البيانات بنجاح ..... ", messagetype: "success" });
                this.setState({ expertbook: {} });
                this.getExpertbooks();
            }
            else {
                this.setState({ message: "هناك مشكلة حصلت ... الرجاء المحاولة مرة آخرى", messagetype: "error" });
            }
        }
    }

    render() {
        const { loading } = this.state;
        if (!loading) {
            return (
                <div className="container m-0 p-0">
                    {this.state.message ? (<Alert message={this.state.message} messageType={this.state.messagetype} />) : null}
                    <div className="card m-0 p-0">
                        <div className="card-header clearfix">
                            <div className="row">
                                <div className="col-sm-2 d-flex align-items-end">
                                    <button onClick={(e) => this.setState({ showexpertbooksFormok: !this.state.showexpertbooksFormok })} className="btn btn-success"><FaPlus /> </button>
                                </div>
                                <div className="col-sm-7 align-self-center">
                                    <h4 className="text-center"> المؤلفات </h4>
                                </div>
                                <div className="col-sm-3 d-flex align-items-start">
                                    <Link to={`/expertdomains/${this.state.expertid}`} className="btn btn-primary m-2"> <FaAngleRight /> السابق</Link>
                                    <Link to={`/expertresearchs/${this.state.expertid}`} className="btn btn-primary m-2"> التالي <FaAngleLeft /></Link>
                                </div>
                            </div>
                        </div>
                        {this.state.showexpertbooksFormok ? (
                            <div className="card-body">
                                <div className="col-sm-12 text-center">
                                    <form className="form-horizontal" onSubmit={e => e.preventDefault() && false}>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">العنوان</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.title) })} id="title" autoComplete="off" defaultValue={this.state.expertbook.title} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.title)} className='feedback'>{this.state.formErrors.title ? this.state.formErrors.title : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">المؤلف</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.authors) })} id="authors" autoComplete="off" defaultValue={this.state.expertbook.authors} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.authors)} className='feedback'>{this.state.formErrors.authors ? this.state.formErrors.authors : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">سنة النشر</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.release_date) })} id="release_date" autoComplete="off" defaultValue={this.state.expertbook.release_date} onChange={this.setHandleChange} type="number" min="1960" max="2020" />
                                                <span hidden={!(this.state.formErrors.release_date)} className='feedback'>{this.state.formErrors.release_date ? this.state.formErrors.release_date : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">دار النشر</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.publisher) })} id="publisher" autoComplete="off" defaultValue={this.state.expertbook.publisher} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.publisher)} className='feedback'>{this.state.formErrors.publisher ? this.state.formErrors.publisher : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 control-label">بلد النشر</label>
                                            <div className="col-sm-8">
                                                <input className={classnames({ 'form-control': true, 'errors': (this.state.formErrors.country) })} id="country" autoComplete="off" defaultValue={this.state.expertbook.country} onChange={this.setHandleChange} />
                                                <span hidden={!(this.state.formErrors.country)} className='feedback'>{this.state.formErrors.country ? this.state.formErrors.country : ''} </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12 text-center">
                                                <button type="submit" className="btn btn-primary m-2" onClick={this.saveExpertBook}><FaPlus /> حــفـــظ </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-footer">
                            <div className="row">
                                {this.state.expertbooks && this.state.expertbooks.length > 0 ? this.state.expertbooks.map(expertbook => (
                                    <div className="card border-secondary m-3 mx-auto" style={{ width: "18rem" }} key={expertbook.id}>
                                        <div className="card-body text-secondary">
                                            <p className="card-text text-justify">العنوان: {expertbook.title}</p>
                                            <p className="card-text text-justify">المؤلف: {expertbook.authors}</p>
                                            <p className="card-text text-justify">السنة : {expertbook.release_date}</p>
                                            <p className="card-text text-justify">الناشر: {expertbook.publisher}</p>
                                            <p className="card-text text-justify">الدولة: {expertbook.country}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row col-sm-12 text-center">
                                                <button className="btn btn-secondary btn-md m-1" onClick={(e) => this.editItem(expertbook.id, e)}> <FaRegEdit /> </button>
                                                <button className="btn btn-danger btn-md m-1" onClick={(e) => this.deleteItem(expertbook.id, e)}> <FaRegTrashAlt /> </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div> لا يوجد نتائج </div>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ExpertBooks;